<template>
    <router-view></router-view>
</template>

<script>
    export default {
        name: 'Leads'
    }
</script>
<style lang="scss" scoped>

</style>
